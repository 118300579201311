import _defineProperty from"@babel/runtime/helpers/esm/defineProperty";import _toConsumableArray from"@babel/runtime/helpers/esm/toConsumableArray";function ownKeys(object,enumerableOnly){var keys=Object.keys(object);if(Object.getOwnPropertySymbols){var symbols=Object.getOwnPropertySymbols(object);enumerableOnly&&(symbols=symbols.filter(function(sym){return Object.getOwnPropertyDescriptor(object,sym).enumerable;})),keys.push.apply(keys,symbols);}return keys;}function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=null!=arguments[i]?arguments[i]:{};i%2?ownKeys(Object(source),!0).forEach(function(key){_defineProperty(target,key,source[key]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(target,Object.getOwnPropertyDescriptors(source)):ownKeys(Object(source)).forEach(function(key){Object.defineProperty(target,key,Object.getOwnPropertyDescriptor(source,key));});}return target;}import"core-js/modules/es.array.concat.js";import"core-js/modules/es.object.keys.js";import"core-js/modules/es.symbol.js";import"core-js/modules/es.array.filter.js";import"core-js/modules/es.object.to-string.js";import"core-js/modules/es.object.get-own-property-descriptor.js";import"core-js/modules/es.array.push.js";import"core-js/modules/web.dom-collections.for-each.js";import"core-js/modules/es.object.get-own-property-descriptors.js";import"core-js/modules/es.object.define-properties.js";import"core-js/modules/es.object.define-property.js";var StatusQueryKeys={global:['statuses'],/**
   * Status Details
   */ /** @deprecated Internal: do not use */details:function details(){return[].concat(_toConsumableArray(StatusQueryKeys.global),['details']);},/** Used to fetching a single Status */detail:function detail(statusId){return[].concat(_toConsumableArray(StatusQueryKeys.details()),[statusId]);},/** Used for fetching ancestors on a single Status */ancestors:function ancestors(statusId){return[].concat(_toConsumableArray(StatusQueryKeys.detail(statusId)),['ancestors']);},/** Used for fetching descendants on a single Status */descendants:function descendants(statusId,sort){return[].concat(_toConsumableArray(StatusQueryKeys.detail(statusId)),['descendants',sort]);},/** Used for fetching favourites on a single Status */favourites:function favourites(id){return[].concat(_toConsumableArray(StatusQueryKeys.detail(id)),['favourites']);},/**
   * Home Timeline
   */timeline:function timeline(timelineId,pinKey){return[].concat(_toConsumableArray(StatusQueryKeys.global),['timeline',timelineId,pinKey]);},/**
   * Account Timeline (Profile)
   */ /** @deprecated Internal: do not use */accounts:function accounts(){return[].concat(_toConsumableArray(StatusQueryKeys.global),['account']);},/** Used to bust the cache of a particular account's statuses */accountId:function accountId(_accountId){var params=arguments.length>1&&arguments[1]!==undefined?arguments[1]:{};return[].concat(_toConsumableArray(StatusQueryKeys.accounts()),[_accountId]);},/** Used for fetching Statuses from a single Account */account:function account(accountId){var params=arguments.length>1&&arguments[1]!==undefined?arguments[1]:{};return[].concat(_toConsumableArray(StatusQueryKeys.accounts()),[accountId,_objectSpread({},params)]);},/** Used for fetching Favourited Statuses from my Account */accountFavourites:function accountFavourites(accountId){return[].concat(_toConsumableArray(StatusQueryKeys.account(accountId)),['favourites']);},/**
  * Group Timeline
  */groupTimeline:function groupTimeline(groupId,pinned){return[].concat(_toConsumableArray(StatusQueryKeys.global),['group',groupId,'timeline','pinned',String(pinned)]);},groupMedia:function groupMedia(groupId){return[].concat(_toConsumableArray(StatusQueryKeys.global),['group',groupId,'media']);},/**
   * Tag Timeline
   */tagTimeline:function tagTimeline(tagName){return[].concat(_toConsumableArray(StatusQueryKeys.global),['tag',tagName]);},/**
   * Miscellaneous
   */mutes:function mutes(){return[].concat(_toConsumableArray(StatusQueryKeys.global),['mutes']);},trending:function trending(){return[].concat(_toConsumableArray(StatusQueryKeys.global),['trending']);}};export{StatusQueryKeys};